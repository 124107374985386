:global(.formio-dialog-content) {
    background-color: var(--vz-light) !important;    
    :global(.ace-xcode) {
        color: var(--vz-card-color) !important;
        background-color: var(--vz-light) !important;
        :global(.ace_gutter) {
            background-color: var(--vz-light) !important;
        }
    }
    :global(.formio-component) {        
        th {
            color: var(--vz-body-color) !important;
        }
    }
}