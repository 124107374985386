.dts-chatbot {
    position: fixed !important;
    right: 0px;
    bottom: 10px;
    z-index: 999;
    width: 600px;

    .box.box-warning {
        border-top-color: #f39c12;
    }

    .box {
        position: relative;
        border-radius: 3px;
        border-top: 3px solid #d2d6de;
        box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    }
    .box-header.with-border {
        border-bottom: 1px solid #f4f4f4
    }

    .box-header.with-border {
        border-bottom: 1px solid #f4f4f4;
    }

    .box-header {
        color: #444;
        display: block;
        padding: 10px;
        position: relative;
    }

    .box-header {
        color: #444;
        display: block;
        padding: 10px;
        position: relative
    }

    .box-header>.fa, .box-header>.glyphicon, .box-header>.ion, .box-header .box-title {
        display: inline-block;
        font-size: 18px;
        margin: 0;
        line-height: 1;
    }

    .direct-chat .box-body {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        overflow-x: hidden;
        padding: 0;
    }

    .box-body {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        padding: 10px;
    }

    .direct-chat-messages {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        padding: 10px;
        height: 380px;
        overflow: auto;
    }

    .chatboat-error {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        height: 380px;
        text-align: center;
    }

    .chatboat-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 380px;
    }

    .direct-chat-messages, .direct-chat-contacts {
        -webkit-transition: -webkit-transform .5s ease-in-out;
        -moz-transition: -moz-transform .5s ease-in-out;
        -o-transition: -o-transform .5s ease-in-out;
        transition: transform .5s ease-in-out;
    }

    .direct-chat-msg {
        margin-bottom: 10px;
    }

    .direct-chat-msg, .direct-chat-text {
        display: block;
    }

    .direct-chat-info {
        display: block;
        margin-bottom: 2px;
        font-size: 12px;
    }

    .direct-chat-timestamp {
        color: #999;
    }

    .direct-chat-img {
        border-radius: 50%;
        float: left;
        width: 40px;
        height: 40px;
    }

    .direct-chat-text {
        border-radius: 5px;
        position: relative;
        padding: 5px 10px;
        margin: 5px 0 0 50px;
    }

    .direct-chat-msg, .direct-chat-text {
        display: block;
    }

    .direct-chat-text:before {
        border-width: 6px;
        margin-top: -6px;
    }

    .direct-chat-text:after, .direct-chat-text:before {
        position: absolute;
        right: 100%;
        top: 15px;
        border: solid transparent;
        border-right-color: #d2d6de;
        content: ' ';
        height: 0;
        width: 0;
        pointer-events: none;
    }

    .direct-chat-text:after {
        border-width: 5px;
        margin-top: -5px;
    }

    .direct-chat-text:after, .direct-chat-text:before {
        position: absolute;
        right: 100%;
        top: 15px;
        border: solid transparent;
        border-right-color: #d2d6de;
        content: ' ';
        height: 0;
        width: 0;
        pointer-events: none;
    }

    :after, :before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .direct-chat-msg:after {
        clear: both;
    }

    .direct-chat-msg:after {
        content: " ";
        display: table;
    }

    .direct-chat-info {
        display: block;
        margin-bottom: 2px;
        font-size: 12px;
    }

    .right .direct-chat-img {
        float: right;
    }

    .right .direct-chat-text {
        margin-right: 50px;
        margin-left: 0;
    }

    .box-footer {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        padding: 10px;
    }


    .input-group-btn {
        position: relative;
        font-size: 0;
        white-space: nowrap;
    }

    .input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group {
        z-index: 2;
        margin-left: -1px;
    }
    .shimmer {
        color: grey;
        display:inline-block;
        -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
        background-repeat: no-repeat;
        animation: shimmer 2.5s infinite;
        font-size: 100px;
        max-width:200px;
    }
    .text-wrap {
        word-wrap: break-word; /* Forces text to wrap within the container */
        word-break: break-word; /* Ensures long words break to fit */
        white-space: normal; /* Allows text to wrap onto the next line */
        overflow-wrap: break-word; /* Ensures overflowed text wraps */
    }
      
    @keyframes shimmer {
        100% {-webkit-mask-position:left}
    }
    .ql-container.ql-snow {
        height: 60px;
        max-height: 60px;
        background-color: var(--#{$prefix}light);
    }
}