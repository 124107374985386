.dtsloader
{ 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 999;
    .loader{
        margin: auto;
        border: 3px solid #EAF0F6;
        border-radius: 50%;
        border-top: 4px solid #2D9CCA;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: spinner 1s linear infinite;
        img {
            margin: auto;
            width: 30px;
            height: 30px;
        }
    }
    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}