//
//_student.scss
//
.students-table {
    tr {
        th {
            justify-content: center;
            text-align: left;
        }

        td {
            div {
                justify-content: left;
            }

            .multi-item {
                justify-content: space-between;
            }
        }

        .btn_preview {
            display: none;
        }

        .hoverbutton {
            display: none;
        }
        .otherth {
            width: 160px;            
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

    }

    tr:hover {
        .btn_preview {
            display: block;
        }
    }

    td {
        position: relative;
        /* Ensure relative positioning */
    }

    tr:hover {
        overflow: visible;

        /* Allow overflow to show the button */
        .hoverbutton {
            display: block;
            margin-left: 10px;
        }
        .text-ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // max-width: 127px;
        }
        .text-ellipsis {
            a {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    tr {
        .text-ellipsis {
            // overflow: hidden;
            white-space: wrap;
            // text-overflow: ellipsis;
            // max-width: 127px;
        }
    }

    td .btn_preview {
        position: absolute;
        /* Position the button absolutely */
        top: 50%;
        /* Align vertically to the middle */
        right: 0;
        /* Align to the right */
        transform: translateY(-50%);
        /* Adjust for vertical alignment */
        opacity: 0;
        /* Initially hide the button */
        transition: opacity 0.3s ease;
        /* Add transition for smooth opacity change */
    }

    tr:hover .btn_preview {
        opacity: 1;
        /* Show the button on hover */
    }


    td .btn_group {
        position: absolute;
        /* Position the button absolutely */
        top: 50%;
        /* Align vertically to the middle */
        right: 0;
        /* Align to the right */
        transform: translateY(-50%);
        /* Adjust for vertical alignment */
        opacity: 0;
        /* Initially hide the button */
        transition: opacity 0.3s ease;
        /* Add transition for smooth opacity change */
    }

    tr:hover .btn_group {
        opacity: 1;
        /* Show the button on hover */
    }

    td {
        font-weight: 600;
        padding: 8px !important;
        a {
            font-weight: 600;
        }

    }

    th {
        color: var(--vz-table-color-state, var(--vz-table-color-type, var(--vz-table-color)));
    }
}

.table-bottom-save-section {
    position: fixed;
    bottom: 0;
    z-index: 999;
    padding: 1.5rem 1.5rem;
    box-shadow: var(--vz-page-title-box-shadow);
    border-bottom: 1px solid var(--vz-page-title-border);
    background-color: var(--vz-body-bg);
}

.custom-primsetreeselect {
    .p-treeselect-panel {
        z-index: 1051;
    }
}
.p-treeselect-panel {
    z-index: 1051 !important;
}