.dts-select-tree {
    .rct-title, .rct-text {
        font-size: 14px;
    }
    .react-checkbox-tree label:hover {
        background: $primary-bg-subtle-dark;
    }
    .dropdown-menu  {
        max-height: 300px;
        overflow: scroll;
        min-width: 300px;
    }
    .dts-select-tree-input {
        max-height: 200px;
        overflow-y: scroll;
    }
}