//
// toast.scss
//


// toast
.toast { 
    &:not(:last-child) {
      margin-bottom: $toast-padding-x;
    }
}

@mixin toast-border-variant($bg) {
    .toast-body{
        color: $bg;
        border-bottom: 3px solid $bg;
        padding: 20px;
    }
}

@each $color, $value in $theme-colors {
    .toast-border-#{$color} {
        @include toast-border-variant($value);
    }
}